import base from './base.config';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json; charset=utf-8',
};

const config = {
  development: {
    headers,
    baseURL: 'http://localhost:5000/api',
  },
  production: {
    headers,
    baseURL: 'https://casa-da-pizza.herokuapp.com/api',
  },
};

export default Object.freeze(config[base.mode]);
