import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Entry({ className, slug, label, exact }) {
  return (
    <li className={`nav-item ${className}`}>
      <S.NavLink
        className="nav-link"
        activeClassName="--active"
        to={slug}
        exact={exact}
      >
        {label}
      </S.NavLink>
    </li>
  );
}

Entry.defaultProps = { className: '', exact: false };

Entry.propTypes = {
  className: PropTypes.string,
  exact: PropTypes.bool,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Entry;
