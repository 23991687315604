import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from '../Header';
import Navbar from '../Navbar';
import SEO from '../SEO';
import Sidebar from '../Sidebar';

import Routes from '../../routes';

import GlobalStyle from '../../styles/global';

const Layout = ({ className, children, header, headerBg, title, match }) => {
  const [sidebarState, setSidebarState] = useState(false);

  return (
    <div className={className}>
      <GlobalStyle />
      <SEO title={title} />

      <Sidebar
        onClose={() => setSidebarState(false)}
        active={sidebarState}
        entries={Routes.filter(each => each.sidebar)
          .map(each => ({
            ...each,
            slug: each.path,
          }))
          .sort((a, b) => a.index - b.index)}
      />
      <Navbar
        root={Routes.find(each => each.path === match.path).sidebar || false}
        onSidebarToggle={() => setSidebarState(true)}
      />

      <Header imageBg={headerBg}>{header}</Header>

      <main>{children}</main>
    </div>
  );
};

Layout.defaultProps = { className: '', header: null, headerBg: false };

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  headerBg: PropTypes.bool,
  title: PropTypes.string.isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
};

export default withRouter(Layout);
