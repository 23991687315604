import { MapMarkedAlt as map } from 'styled-icons/fa-solid/MapMarkedAlt';
import { Phone as phone } from 'styled-icons/material/Phone';
import { Share as share } from 'styled-icons/material/Share';
import { Waze as waze } from 'styled-icons/fa-brands/Waze';
import { Whatsapp as whatsapp } from 'styled-icons/fa-brands/Whatsapp';

export const Map = map;
export const Phone = phone;
export const Share = share;
export const Waze = waze;
export const WhatsApp = whatsapp;

export default { Map, Phone, Share, Waze, WhatsApp };
