import React, { useState, useEffect } from 'react';

import { $contacts } from '../../services';

import { Map, Phone, Waze } from '../../styles/icons';

import GoogleMap from '../../components/Map';

import * as S from './styled';

function AboutPage() {
  const [state, setState] = useState({
    loading: false,
    location: {},
    userLocation: {},
    phones: [],
    photos: [],
  });

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }));

    $contacts
      .fetchAll()
      .then(payload => {
        setState(prev => ({ ...prev, ...payload }));

        navigator.geolocation.getCurrentPosition(({ coords }) => {
          setState(prev => ({
            ...prev,
            userLocation: `${coords.latitude},${coords.longitude}`,
          }));
        });
      })
      .finally(() => {
        setState(prev => ({ ...prev, loading: false }));
      });
  }, []);

  const Header = (
    <>
      <S.HeaderTitle>O Nosso Espaço</S.HeaderTitle>
      {state.loading ? <S.AboutHeaderSpinner active /> : null}
      {!state.loading && state.phones && state.location ? (
        <>
          <S.PhonesList>
            {state.phones.map(phone => (
              <S.PhoneItem key={phone}>
                <S.PhoneItemIcon>
                  <Phone />
                </S.PhoneItemIcon>
                <S.PhoneItemLabel>{phone}</S.PhoneItemLabel>
              </S.PhoneItem>
            ))}
          </S.PhonesList>
          <S.Address>{state.location.address}</S.Address>
        </>
      ) : null}
    </>
  );

  return (
    <S.AboutPageLayout title="Sobre nós" header={Header}>
      {state.loading ? <S.AboutMainSpinner /> : null}
      {!state.loading && state.location && state.phones ? (
        <>
          <S.MapWrapper className="map-wrapper">
            <GoogleMap center={state.location} zoom={17} />
          </S.MapWrapper>
          <S.MapActionsWrapper>
            <S.MapActionsTitle>Descubra como chegar:</S.MapActionsTitle>
            <S.MapActionsContainer>
              <S.MapWazeButton
                href={`https://www.waze.com/ul?q=${state.location.address}&navigate=yes&zoom=17`}
                target="_blank"
                rel="noreferred noopener"
              >
                <div className="icon">
                  <Waze />
                </div>
                Waze
              </S.MapWazeButton>
              <S.MapMapsButton
                href={`https://www.google.com/maps/dir/?api=1&origin=Current Location&destination=${state.location.lat},${state.location.lng}`}
                target="_blank"
                rel="noreferred noopener"
              >
                <div className="icon">
                  <Map />
                </div>
                G Maps
              </S.MapMapsButton>
            </S.MapActionsContainer>
          </S.MapActionsWrapper>
          <S.PhotosWrapper>
            <S.PhotosWrapperTitle>
              Desfrute do nosso espaço!
            </S.PhotosWrapperTitle>

            {state.photos.length > 0 ? (
              <S.PhotosContainer>
                {state.photos
                  .filter(each => each.id)
                  .map(each => (
                    <S.Photo className="photo" bg={each.url} key={each.id} />
                  ))}
              </S.PhotosContainer>
            ) : null}
          </S.PhotosWrapper>
          <S.EndWrapper>
            <S.EndMessage>Bon Appetit!</S.EndMessage>
          </S.EndWrapper>
        </>
      ) : null}
    </S.AboutPageLayout>
  );
}

export default AboutPage;
