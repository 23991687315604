import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Prices from '../Prices';
import Thumb from '../Thumb';
import PromoTag from '../PromoTag';

export const LinkWrapper = styled(Link)`
  width: fit-content;
  &,
  :hover,
  :focus {
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  background-color: var(--light);
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.24);
  position: relative;
`;

export const Tag = styled(PromoTag)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
`;

export const Thumbnail = styled(Thumb)`
  height: 10rem;
`;

export const Content = styled.div`
  padding: 1rem 1.6rem 1.6rem 1.6rem;
`;

export const Title = styled.h4`
  color: var(--dark);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 2.4rem;
  line-height: 120%;
  margin: 0 0 1rem 0;
`;

export const PricesWrapper = styled(Prices)``;
