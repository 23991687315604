import React from 'react';
import PropTypes from 'prop-types';

import seamlessBg from '../../assets/images/seamless-background.png';

import * as S from './styled';

function Thumb({ externalPath, title, className }) {
  return externalPath ? (
    <img
      src={externalPath}
      className={className}
      alt={`Pizza de ${title}`}
      title={`Pizza de ${title}`}
    />
  ) : (
    <S.SeamlessThumb
      src={seamlessBg}
      className={className}
      alt="Sem imagem do produto"
      title="Sem imagem do produto"
    />
  );
}

Thumb.propTypes = {
  className: PropTypes.string,
  externalPath: PropTypes.string,
  title: PropTypes.string,
};

Thumb.defaultProps = {
  className: '',
  externalPath: '',
  title: '',
};

export default Thumb;
