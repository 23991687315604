import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const Wrapper = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility 400ms;
  visibility: hidden;
  width: 100%;
  will-change: visibility;
  z-index: 999;

  &.--active {
    visibility: visible;
    > .overlay {
      opacity: 1;
    }
    > .sidebar {
      transform: translate(0);
    }
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.64);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 400ms;
  width: 100%;
  will-change: opacity;
  z-index: 99;
`;

export const Sidebar = styled.nav`
  background-color: var(--primaryColor);
  box-shadow: 4px 0 8px 2px rgba(0, 0, 0, 0.24);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  max-width: 32rem;
  padding: 3.2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 400ms;
  transform: translate(-100%);
  width: 80%;
  will-change: transform;
  z-index: 999;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  color: var(--darkest);
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  font-size: 1.6rem;
  line-height: 128%;
  padding: 2.4rem 0 2.4rem 1.2rem;
  text-transform: uppercase;
  transform-origin: left center;
  transition: color 400ms, text-shadow 400ms, transform 400ms;

  &,
  &:hover,
  &:active {
    text-decoration: none;
  }

  :hover {
    color: var(--dark);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  :active {
    text-shadow: 0 0 0 transparent;
    transform: scale(0.88);
  }

  &.--active {
    color: var(--dark);
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  }
`;

export const NavLinks = styled.ul`
  margin: 1.6rem 0 0 0;
`;

export const SocialLinks = styled.ul`
  align-items: center;
  bottom: 32px;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  width: 100%;

  > .social-item:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export const SocialLink = styled.a`
  align-items: center;
  background-color: var(--darkest);
  border-radius: 50%;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  color: var(--primaryColor);
  cursor: pointer;
  display: flex;
  font-size: 1.8rem;
  height: 4.8rem;
  justify-content: center;
  transform-origin: center;
  transition: background-color 400ms, box-shadow 400ms, transform 400ms;
  width: 4.8rem;
  will-change: background-color, box-shadow, transform;

  &,
  :hover,
  :active {
    text-decoration: none;
  }

  :hover {
    background-color: var(--dark);
    box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.3);
  }

  :active {
    box-shadow: 0 0 0 0 transparent;
    transform: scale(0.8);
  }
`;

export const SocialIconWrapper = styled.div`
  fill: var(--primaryColor);
  height: 2.4rem;
  width: 2.4rem;
`;

export const TogglerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 4.8rem;
  outline-color: transparent;
  transform-origin: center;
  transition: transform 400ms;
  width: 4.8rem;
  will-change: transform;

  :hover {
    transform: scale(1.12);
  }

  :active {
    transform: scale(0.88);
  }
`;
