import styled from 'styled-components';

export const ShareButton = styled.a`
  align-items: center;
  background-color: var(--primaryColor);
  border-radius: 50%;
  bottom: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: center;
  position: fixed;
  right: 16px;
  width: 4.8rem;
  @media (min-width: 1024px) {
    bottom: 24px;
    right: 24px;
  }
`;

export const ShareIcon = styled.div`
  color: var(--darkest);
  fill: var(--darkest);
  height: 2.4rem;
  width: 2.4rem;
`;
