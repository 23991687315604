import styled, { css } from 'styled-components';

import HeaderBackground from '../../assets/images/pizza-background.jpg';

const withBackground = css`
  &.--image-background {
    background-image: url(${HeaderBackground});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Header = styled.header`
  background-color: var(--primaryColor);
  border-radius: 0 0 4rem 4rem;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  padding: 2rem 3.2rem 4.2rem 3.2rem;

  ${withBackground}
`;
