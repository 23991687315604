import styled from 'styled-components';

import Group from '../../components/Group';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';

export const AboutSpinner = styled(Spinner)`
  padding: 3.2rem;
  > div {
    border-bottom-color: var(--darkest);
    border-width: 0.64rem;
    height: 4rem;
    width: 4rem;
  }
`;

export const AboutHeaderSpinner = styled(AboutSpinner)`
  padding: 5.6rem 3.2rem 3.2rem 3.2rem;
`;

export const AboutMainSpinner = styled(AboutSpinner)`
  padding: 8rem 3.2rem;
`;

export const AboutPageLayout = styled(Layout)`
  > header {
    z-index: 9;
  }

  > main {
    position: relative;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 2.4rem;
  text-align: center;
`;

export const PhonesList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3.2rem 0;
`;

export const PhoneItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  :not(:last-of-type) {
    margin: 0 0 0.8rem 0;
  }

  > div {
    margin: 0 0.4rem 0 0;
  }
`;

export const PhoneItemIcon = styled.div`
  color: var(--darkest);
  fill: var(--darkest);
  height: 2rem;
  width: 2rem;
`;

export const PhoneItemLabel = styled.p`
  color: var(--darkest);
  font-size: 1.6rem;
`;

export const Address = styled.p`
  font-size: 1.6rem;
  line-height: 128%;
  padding: 0 3.2rem;
  text-align: center;
`;

const titlesBase = styled.h3`
  font-family: 'Roboto Medium', 'Roboto', sans-serif;
  font-size: 1.8rem;
  margin: 0 0 1.6rem 0;

  @media (min-width: 1024px) {
    margin: 0 0 2.4rem 0;
    text-align: center;
  }
`;

const wrappersBase = styled.div`
  border-radius: 3.2rem 3.2rem 0 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.24);
  padding: 3.2rem;
  transform: translate(0, 324px);

  @media (min-width: 1024px) {
    padding: 4rem;
  }
`;

export const MapWrapper = styled.div`
  background-color: #ededed;
  height: 40rem;
  left: 0;
  position: absolute;
  top: -36px;
  width: 100%;
  z-index: -1;
`;

export const MapActionsWrapper = styled(wrappersBase)`
  background-color: var(--lightest);
  padding-bottom: 6.4rem;
`;

export const MapActionsTitle = styled(titlesBase)`
  color: var(--darkest);
`;

export const MapActionsContainer = styled.div`
  column-gap: 0.8rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;

  @media (min-width: 1200px) {
    margin: 0 auto;
    max-width: 1200px;
  }

  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

const MapActionBase = styled.a`
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: var(--lightest);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  height: 100%;
  justify-content: center;
  min-height: 4.8rem;
  padding: 0.4rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 400ms;
  width: 100%;
  will-change: transform;

  > .icon {
    fill: var(--lightest);
    margin: 0 0.8rem 0 0;
    height: 2rem;
    width: 2rem;
  }

  :hover {
    transform: scale(1.08);
  }

  :active {
    transform: scale(0.88);
  }
`;

export const MapWazeButton = styled(MapActionBase)`
  background-color: #1eaedb;
`;

export const MapMapsButton = styled(MapActionBase)`
  background-color: #3079ed;
`;

export const PhotosWrapper = styled(wrappersBase)`
  background-color: var(--darkest);
  margin: -3.6rem 0 0 0;

  @media (min-width: 1024px) {
    margin: -1rem 0 0 0;
  }
`;

export const PhotosWrapperTitle = styled(titlesBase)`
  color: var(--lightest);
`;

export const Photo = styled.div`
  background-image: ${props => `url('${props.bg}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: 18rem;
  width: 28rem;

  @media (min-width: 1024px) {
    height: 24rem;
    width: 100%;
  }
`;

export const PhotosContainer = styled(Group)`
  &,
  > div {
    padding: 0;
  }

  > div {
    @media (min-width: 1024px) {
      column-gap: 1rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
    }

    @media (min-width: 1200px) {
      margin: 0 auto;
      max-width: 1200px;
    }

    @media (min-width: 1400px) {
      max-width: 1400px;
    }
  }

  .photo:not(:last-of-type) {
    margin: 0 1rem 0 0;
    @media (min-width: 1024px) {
      margin: 0;
    }
  }
`;

export const EndWrapper = styled.div`
  background-color: var(--primaryColor);
  padding: 3.2rem;
  transform: translate(0, 324px);
`;

export const EndMessage = styled.p`
  color: var(--darket);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 2.4rem;
  text-align: center;
`;
