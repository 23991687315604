import React from 'react';
import PropTypes from 'prop-types';

import Icons from './icons';

import * as S from './styled';

function Close({ onClick }) {
  return (
    <S.TogglerButton className="button sidebar-toggler" onClick={onClick}>
      <Icons.chevron />
    </S.TogglerButton>
  );
}

Close.propTypes = { onClick: PropTypes.func.isRequired };

export default Close;
