import React from 'react';
import PropTypes from 'prop-types';

import Tag from '../Tag';

import PricesType from '../Prices/type';

function PromoTag({ className, prices, small }) {
  const { promo } = prices.find(each => each.promo) || { promo: null };

  return promo ? (
    <Tag className={className} label={promo.title} small={small} />
  ) : null;
}

PromoTag.defaultProps = {
  className: '',
  small: true,
};

PromoTag.propTypes = {
  className: PropTypes.string,
  prices: PricesType.isRequired,
  small: PropTypes.bool,
};

export default PromoTag;
