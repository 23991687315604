import styled from 'styled-components';

import Group from '../../components/Group';
import Search from '../../components/Search';
import Spinner from '../../components/Spinner';

const baseGroup = styled(Group)`
  &,
  > * {
    padding: 0;
  }

  > h1 {
    @media (min-width: 1024px) {
      text-align: center;
    }
  }
  > div {
    @media (min-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1000px;
    }
    @media (min-width: 1200px) {
      max-width: 1200px;
    }
    @media (min-width: 1400px) {
      max-width: 1400px;
    }
  }
`;

export const HomeHeader = styled.div`
  height: 16rem;
`;

export const PromosGroup = styled(baseGroup)`
  margin: 3.2rem 3.2rem 0 3.2rem;
  .promo {
    width: 28rem;
    @media (min-width: 1200px) {
      width: 32rem;
    }
  }
`;

export const FlavoursGroup = styled(baseGroup)`
  margin: 0 3.2rem 1.6rem 3.2rem;
  > div {
    padding: 0.8rem 0;
    > a {
      width: 20rem;
    }
  }
`;

export const HomeSearch = styled(Search)`
  * {
    opacity: 1;
  }
`;

export const SearchGroup = styled(baseGroup)`
  margin: 4rem 3.2rem 0 3.2rem;
`;

export const GroupSpinner = styled(Spinner)`
  height: 20rem;
  padding: 0.8rem 0 0 0;
  > div {
    border-bottom-color: #000;
    border-width: 0.64rem;
    height: 4.8rem;
    width: 4.8rem;
  }
`;
