import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Brand from '../Brand';

const baseButton = styled(Link)`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: center;
  outline-color: transparent;
  transform-origin: center;
  transition: transform 400ms;
  width: 4.8rem;
  will-change: transform;

  &,
  :hover,
  :focus {
    text-decoration: none;
  }

  :hover {
    transform: scale(1.12);
  }

  :active {
    transform: scale(0.88);
  }
`;

export const NavButtonIconWrapper = styled.div`
  color: var(--darkest);
  fill: var(--darkest);
  height: 2rem;
  width: 2rem;
`;

export const SidebarTogglerBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: center;
  outline-color: transparent;
  transform-origin: center;
  transition: transform 400ms;
  width: 4.8rem;
  will-change: transform;

  > ${NavButtonIconWrapper} {
    height: 3.2rem;
    width: 3.2rem;
  }

  :hover {
    transform: scale(1.12);
  }

  :active {
    transform: scale(0.88);
  }
`;

export const GoBack = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--darkest);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  height: 4.8rem;
  justify-content: center;
  outline-color: transparent;
  transform-origin: center;
  transition: color 400ms, transform 400ms;
  width: fit-content;
  will-change: color, transform;

  :hover {
    transform: scale(1.12);
  }

  :active {
    transform: scale(0.88);
  }
`;

export const Nav = styled.nav`
  align-items: center;
  background-color: var(--primaryColor);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.8rem 3.2rem;
  width: 100%;
`;

export const NavBrand = styled(Brand)`
  cursor: pointer;
  justify-self: center;
`;

export const SearchToggler = styled(baseButton)`
  justify-self: flex-end;
`;
