import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { $menuEntries, $contacts, $whatsApp } from '../../services';

import FlavourTag from '../../components/FlavourTag';
import Layout from '../../components/Layout';
import PromoTag from '../../components/PromoTag';
import ShareButton from '../../components/ShareButton';

import defaultBg from '../../assets/images/default-flavour-bg.png';
import { Phone, WhatsApp } from '../../styles/icons';

import * as S from './styled';

function FlavourPage({
  match: {
    params: { pathname },
  },
}) {
  const [flavour, setFlavour] = useState({});
  const [contacts, setContacts] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    Promise.all([$menuEntries.fetchOne(pathname), $contacts.fetchPhones()])
      .then(([aPayload, bPayload]) => {
        setFlavour({ ...aPayload });
        setContacts(bPayload);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pathname]);

  return (
    <Layout title={flavour && flavour.title ? flavour.title : pathname}>
      <S.FlavourPageWrapper>
        <S.FlavourContentWrapper>
          {loading ? <S.FlavourContentSpinner active /> : null}
          {!loading && flavour ? (
            <>
              <S.FlavourHeader>
                <S.FlavourTitle>{flavour.title}</S.FlavourTitle>
                {flavour && flavour.prices && flavour.prices.length > 0 ? (
                  <PromoTag prices={flavour.prices} small />
                ) : null}
              </S.FlavourHeader>
              <S.FlavourIngridients>{flavour.ingridients}</S.FlavourIngridients>
              <S.FlavourTagsWrapper>
                {flavour.tags
                  ? flavour.tags.map(tag => (
                      <FlavourTag target={tag} label={tag} key={tag} />
                    ))
                  : null}
              </S.FlavourTagsWrapper>
              {flavour.photo ? (
                <S.FlavourPhoto bg={flavour.photo} />
              ) : (
                <S.FlavourPhoto bg={defaultBg} />
              )}
            </>
          ) : null}
        </S.FlavourContentWrapper>
        <S.FlavourContactsWrapper>
          {loading ? <S.FlavourContactsSpinner active /> : null}
          {!loading && flavour && contacts ? (
            <>
              {flavour.prices ? (
                <S.FlavourPricesWrapper prices={flavour.prices} fullDesc />
              ) : null}

              <S.FlavourContactsContainer>
                <S.PhoneButton
                  href={`tel:${contacts.phones}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className="icon">
                    <Phone />
                  </div>
                  Pedir Agora!
                </S.PhoneButton>
                <S.WhatsAppButton
                  href={`${$whatsApp.getLink()}phone=${contacts.whatsApp}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className="icon">
                    <WhatsApp />
                  </div>
                  Pedir Agora!
                </S.WhatsAppButton>
              </S.FlavourContactsContainer>
            </>
          ) : null}
        </S.FlavourContactsWrapper>
      </S.FlavourPageWrapper>
      <ShareButton target="sabor" />
    </Layout>
  );
}

FlavourPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ pathname: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
};

export default FlavourPage;
