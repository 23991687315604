import styled from 'styled-components';

import Group from '../../components/Group';
import Spinner from '../../components/Spinner';

export const PageContainer = styled.div`
  padding: 3.2rem 3.2rem 1.6rem 3.2rem;
`;

export const HeaderSpinner = styled(Spinner)`
  height: 20rem;
  padding: 0.8rem 0 0 0;
  > div {
    border-bottom-color: #000;
    border-width: 0.64rem;
    height: 4.8rem;
    width: 4.8rem;
  }
`;

const PageGroup = styled(Group)`
  &,
  > * {
    padding: 0;
  }
`;

export const HeaderGroup = styled(PageGroup)`
  > div {
    @media (min-width: 1024px) {
      justify-content: center;
    }

    @media (min-width: 1200px) {
      margin: 0 auto;
      max-width: 1200px;
    }
  }
  .promo {
    width: 30rem;

    @media (min-width: 1024px) {
      width: 32rem;
    }
  }
`;

export const SearchGroup = styled(PageGroup)`
  margin: 0 0 1.6rem 0;

  @media (min-width: 1024px) {
    padding: 2.4rem 0 3.2rem 0;
    > h1 {
      text-align: center;
    }
    div {
      justify-content: center;
    }
  }
`;

export const ResultsCounter = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.4rem;
  padding: 1.6rem 0;
  text-align: right;
  @media (min-width: 1200px) {
    margin: 2.4rem auto 0 auto;
    max-width: 1200px;
    padding: 0;
  }
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const MenuSpinner = styled(Spinner)`
  padding: 1.6rem 0;

  > div {
    border-width: 0.8rem;
    height: 5.6rem;
    width: 5.6rem;
  }
`;

export const MenuList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    width: 100%;
    margin: 0 0 2.4rem 0;
    @media (min-width: 1024px) {
      margin: 0;
    }
  }

  @media (min-width: 1024px) {
    padding: 1.6rem 0 0.8rem 0;
    column-gap: 1.6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    row-gap: 2.4rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    max-width: 1200px;
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1400px;
  }
`;
