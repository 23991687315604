import React from 'react';
import PropTypes from 'prop-types';

import Price from '../Price';

import Type from './type';

import * as S from './styled';

function Prices({ className, fullDesc, prices }) {
  let payload = prices;
  if (!fullDesc) {
    if (prices.length > 2) payload = [prices[0], prices[prices.length - 1]];
  }

  return (
    <S.Wrapper className={`prices ${className}`}>
      {payload.map(each => (
        <Price {...each} key={each.desc} full={fullDesc} />
      ))}
    </S.Wrapper>
  );
}

Prices.defaultProps = { className: '', fullDesc: false };

Prices.propTypes = {
  className: PropTypes.string,
  fullDesc: PropTypes.bool,
  prices: Type.isRequired,
};

export default Prices;
