import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404</h1>
    <p>Oops... Não encontrei nada aqui!</p>
  </Layout>
);

export default NotFoundPage;
