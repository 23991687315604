import React from 'react';
import PropTypes from 'prop-types';

import Prices from '../Prices';
import PricesType from '../Prices/type';

import * as S from './styled';

function SearchResult({ title, ingridients, pathname, prices }) {
  const { promo } = prices.find(each => each.promo) || { promo: null };
  return (
    <S.SearchCardContainer to={`/menu/${pathname}`}>
      <S.SearchCard className={promo ? '--hasPromo' : null}>
        <S.SearchTag prices={prices} />
        <S.SearchTitle>{title}</S.SearchTitle>
        <S.SearchIngridients>{ingridients}</S.SearchIngridients>
        <Prices prices={prices} />
      </S.SearchCard>
    </S.SearchCardContainer>
  );
}

SearchResult.propTypes = {
  title: PropTypes.string.isRequired,
  ingridients: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  prices: PricesType.isRequired,
};

export default SearchResult;
