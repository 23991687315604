export default [
  { label: 'Calabresa', value: 'calabresa' },
  { label: 'Frango', value: 'frango' },
  { label: 'Lombo', value: 'lombo' },
  { label: 'Atum', value: 'atum' },
  { label: 'Queijos', value: 'queijos' },
  { label: 'Brocolis', value: 'brocolis' },
  { label: 'Pizzas Doces', value: 'doce' },
  { label: 'Esfihas', value: 'esfiha' },
  { label: 'Esfihas Doces', value: 'esfihasDoces' },
  { label: 'Bebidas', value: 'bebida' },
];
