import atum from '../../assets/images/icons/atum.png';
import bebida from '../../assets/images/icons/bebida.png';
import brocolis from '../../assets/images/icons/brocolis.png';
import calabresa from '../../assets/images/icons/calabresa.png';
import doce from '../../assets/images/icons/doce.png';
import esfiha from '../../assets/images/icons/esfiha.png';
import esfihasDoces from '../../assets/images/icons/esfihasDoces.png';
import frango from '../../assets/images/icons/frango.png';
import lombo from '../../assets/images/icons/lombo.png';
import queijos from '../../assets/images/icons/queijos.png';

export default {
  atum,
  bebida,
  brocolis,
  calabresa,
  doce,
  esfiha,
  esfihasDoces,
  frango,
  lombo,
  queijos,
};
