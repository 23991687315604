import styled from 'styled-components';
import { Link } from 'react-router-dom';

import FlavourTag from '../FlavourTag';
import PromoTag from '../PromoTag';
import Thumb from '../Thumb';

export const LinkWrapper = styled(Link)`
  &,
  :hover,
  :focus {
    text-decoration: none;
  }
`;

export const Card = styled.section`
  background-color: var(--light);
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 1.6rem 3.2rem;
  position: relative;
`;

export const Title = styled.h1`
  color: var(--dark);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 2.4rem;
  width: 100%;
`;

export const MenuEntryThumbWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
`;

export const MenuEntryThumb = styled(Thumb)`
  border-radius: 0 8px 0 8px;
  height: 7.2rem;
  min-width: 8rem;
  max-width: 14.4rem;
  object-fit: contain;
  width: auto;
`;

export const MenuEntryFlavourTagsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1.2rem 0 2rem 0;
`;

export const MenuEntryFlavourTag = styled(FlavourTag)`
  flex-grow: 0;
  flex-shrink: 0;
  width: fit-content;

  &:not(:last-child) {
    margin: 0 0.8rem 0 0;
  }
`;

export const MenuEntryPromoTag = styled(PromoTag)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
  white-space: nowrap;
  z-index: 1;
`;

export const Prices = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const Tags = styled.div`
  align-items: center;
  display: flex;
`;
