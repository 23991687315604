import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Option({ label, value, active, className, onClick }) {
  function onClickHandler(e) {
    e.stopPropagation();
    e.preventDefault();

    onClick(value);
  }

  return (
    <S.Wrapper
      className={`option ${className} ${active ? '--active' : ''}`}
      onClick={onClickHandler}
    >
      <S.Icon className="thumb" target={value} small={false} />
      <S.Label className="label">{label}</S.Label>
    </S.Wrapper>
  );
}

Option.defaultProps = { active: false, className: '' };

Option.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Option;
