import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function SidebarToggler({ onClick, icon }) {
  return (
    <S.SidebarTogglerBtn className="sidebar-toggler" onClick={onClick}>
      <S.NavButtonIconWrapper>{icon}</S.NavButtonIconWrapper>
    </S.SidebarTogglerBtn>
  );
}

SidebarToggler.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarToggler;
