import styled from 'styled-components';

import FlavourTag from '../../FlavourTag';

export const Wrapper = styled.div`
  cursor: pointer;
  opacity: 0.64;
  transition: opacity 400ms, transform 320ms;
  will-change: opacity, transform;

  :hover {
    opacity: 0.8;
    > .menu-entry-tag {
      background-color: var(--dark);
      box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.32);
    }

    > .label {
      color: var(--dark);
    }
  }

  :active {
    box-shadow: 0 0 0 0 transparent;
    opacity: 1;
    transform: scale(0.88);
  }

  &.--active {
    opacity: 1;
    > .menu-entry-tag {
      background-color: var(--darkest);
      box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.32);
    }
  }
`;

export const Icon = styled(FlavourTag)`
  margin: 0 auto 1rem auto;
`;

export const Label = styled.p`
  color: var(--darkest);
  font-size: 1.4rem;
  line-height: 120%;
  text-align: center;
  transition: color 400ms;
  will-change: color;
`;
