import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Routes from './routes';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {Routes.map(route => (
            <Route
              path={route.path}
              exact={route.exact}
              component={route.component}
              key={route.name}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
