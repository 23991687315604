import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: -100% -100%;
  opacity: 0;
  transition: margin 100ms, opacity 400ms, visibility 100ms;
  visibility: hidden;
  width: 100%;
  will-change: margin, opacity, visibility;

  &.--active {
    margin: 0;
    opacity: 1;
    visibility: visible;
  }
`;

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s linear infinite;
  border: 1.6rem solid rgba(0, 0, 0, 0.02);
  border-bottom-color: var(--primaryColor);
  border-radius: 50%;
  height: 12.8rem;
  width: 12.8rem;
`;
