import React from 'react';
import { withProps, compose } from 'recompose';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAstN0zhiYIobVIrlMUOcP-jbi80Auc7EE',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: (
      <div style={{ height: '100%' }} className="map-container" />
    ),
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ center, zoom }) => (
  <GoogleMap defaultCenter={center} defaultZoom={zoom}>
    <Marker position={center} />
  </GoogleMap>
));

export default Map;
