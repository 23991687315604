import styled from 'styled-components';

import Option from './Option';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  width: 100%;

  &,
  * {
    user-select: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SearchOption = styled(Option)`
  &:not(:last-child):not(:first-child) {
    margin: 0 1rem;
  }

  &:first-child {
    margin: 0 1rem 0 0;
  }

  &:last-child {
    margin: 0 1.6rem 0 1rem;

    @media (min-width: 1024px) {
      margin: 0;
    }
  }
`;
