import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Tag({ className, label, small }) {
  return (
    <S.TagWrapper className={`tag ${small ? '--small' : ''} ${className}`}>
      <S.TagDecorator className="decorator">#</S.TagDecorator>
      <S.TagLabel className="label">{label}</S.TagLabel>
    </S.TagWrapper>
  );
}

Tag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

Tag.defaultProps = {
  className: '',
  small: false,
};

export default Tag;
