import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Message({ content, active, className }) {
  return (
    <S.MessageWrapper
      className={`wrapper ${active ? '--active' : ''} ${className}`}
    >
      <S.MessageContent>{content}</S.MessageContent>
    </S.MessageWrapper>
  );
}

Message.defaultProps = {
  active: true,
  className: '',
};

Message.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default Message;
