import styled from 'styled-components';

export const GroupWrapper = styled.section`
  padding: 0.8rem 3.2rem;
`;

export const GroupTitle = styled.h1`
  font-family: 'Roboto Medium', 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
`;

export const GroupItemsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0 0 0.8rem 0.8rem;

  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex-basis: 1;
    flex-grow: 0;
    flex-shrink: 0;

    :not(:last-child) {
      margin-right: 1.6rem;
    }
  }
`;
