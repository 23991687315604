import React from 'react';

import { Search } from '../pages';

export default [
  {
    sidebar: false,
    name: 'Search',
    path: '/pesquisa',
    component: props => <Search {...props} />,
  },
];
