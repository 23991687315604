import PropTypes from 'prop-types';

export const propTypes = {
  desc: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  promo: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number,
  }),
};

export default PropTypes.shape(propTypes);
