import axios from 'axios';

import { API as Config, base as BaseConfig } from '../config';

const $http = axios.create({ ...Config });

$http.interceptors.request.use(config => {
  if (BaseConfig.mode === 'development') console.log(config);

  return config;
});

$http.interceptors.response.use(
  response => {
    if (BaseConfig.mode === 'development') console.log(response);
    return response;
  },
  error => {
    if (BaseConfig.mode === 'development') console.log(error);
    return error;
  },
);

export default $http;
