import styled from 'styled-components';

export const MenuEntryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
`;

export const MenuEntryIcon = styled.img`
  object-fit: contain;
  object-position: center;
`;

export const MenuEntryWrapper = styled.span`
  align-items: center;
  background-color: var(--dark);
  border-radius: 50%;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 6.4rem;
  justify-content: center;
  width: 6.4rem;

  &.--small {
    height: 4.4rem;
    width: 4.4rem;
    > .tag-icon {
      height: 2.8rem;
      width: 2.8rem;
    }
  }

  &.--withLabel {
    height: 5rem;
    width: 5rem;
    > .tag-icon {
      height: 3.2rem;
      width: 3.2rem;
    }
  }
`;

export const TagLabel = styled.p`
  color: var(--dark);
  font-size: 1.4rem;
  margin: 0.4rem 0 0 0;
  text-align: center;
`;
