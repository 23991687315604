import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Value({ value, full, desc, mode, currency }) {
  let handledValue = value % 1 === 0 ? `${value}.00` : value;
  handledValue =
    `${handledValue}`.split('.')[1].length === 2
      ? `${handledValue}`
      : `${handledValue}0`;

  return (
    <S.Wrapper
      className={`value ${mode ? `--${mode}` : ''} ${full ? '--full' : ''}`}
    >
      <S.Small className="currency">{currency}</S.Small>
      <S.Value className="value">{`${handledValue}`.replace('.', ',')}</S.Value>
      <S.Small className="desc">{full ? desc : desc.substring(0, 1)}</S.Small>
    </S.Wrapper>
  );
}

Value.propTypes = {
  value: PropTypes.number.isRequired,
  desc: PropTypes.string.isRequired,
  full: PropTypes.bool,
  mode: PropTypes.string,
  currency: PropTypes.string,
};

Value.defaultProps = {
  currency: 'R$',
  full: false,
  mode: '',
};

export default Value;
