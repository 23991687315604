import React from 'react';
import PropTypes from 'prop-types';

import Icons from './icons';

import * as S from './styled';

function Social({ label, link }) {
  const Icon = Icons[label];
  const legend = `${label.slice(0, 1).toUpperCase()}${label
    .slice(1)
    .toLowerCase()}`;

  return (
    <li className="social-item">
      <S.SocialLink
        href={link}
        className="social-link"
        target="blank"
        rel="noreferrer; noopener"
        alt={legend}
        title={legend}
      >
        <S.SocialIconWrapper>
          <Icon />
        </S.SocialIconWrapper>
      </S.SocialLink>
    </li>
  );
}

Social.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Social;
