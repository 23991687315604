import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;

  > .price {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    > .value:not(.--old) {
      .currency,
      .desc {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    > .value:not(.--promo):not(.--old) {
      padding-top: 1rem;
      .value {
        color: var(--primaryColor);
      }
    }

    .--old {
      > .currency,
      > .desc {
        padding: 0;
      }

      > .currency,
      > .desc,
      > .value {
        font-size: 1rem;
      }
    }

    p.value {
      font-size: 1.8rem;
    }
  }
`;
