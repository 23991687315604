import React from 'react';
import PropTypes from 'prop-types';

import PricesType from '../Prices/type';

import * as S from './styled';

function FlavourCard({ title, thumb, pathname, prices }) {
  return (
    <S.LinkWrapper to={`/menu/${pathname}`}>
      <S.Wrapper className="flavour-card">
        <S.Tag prices={prices} small />
        <S.Thumbnail externalPath={thumb} title={title} />
        <S.Content className="content">
          <S.Title className="title">{title}</S.Title>
          <S.PricesWrapper prices={prices} />
        </S.Content>
      </S.Wrapper>
    </S.LinkWrapper>
  );
}

FlavourCard.propTypes = {
  title: PropTypes.string.isRequired,
  thumb: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  prices: PricesType,
};

FlavourCard.defaultProps = {
  thumb: null,
  prices: [],
};

export default FlavourCard;
