import styled from 'styled-components';

export const TagWrapper = styled.div`
  background-color: var(--secondaryColor);
  border-radius: 4px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.24);
  position: relative;
  width: fit-content;
  padding: 0.8rem 1.6rem;

  &.--small {
    padding: 0.8rem 1.28rem;

    > .decorator {
      font-size: 1.8rem;
      left: 7px;
    }
    > .label {
      font-size: 1.2rem;
    }
  }
`;

export const TagDecorator = styled.span`
  color: rgba(255, 255, 255, 0.32);
  font-size: 2.4rem;
  font-weight: 800;
  left: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const TagLabel = styled.p`
  color: var(--lightest);
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  text-transform: uppercase;
`;
