import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Prices from '../Prices';
import PromoTag from '../PromoTag';

import Flames from '../../assets/images/flames.png';

export const Container = styled(Link)`
  text-decoration: none;
`;

export const Wrapper = styled.article`
  background: url(${Flames}),
    linear-gradient(to right bottom, #000 0%, #212121 100%);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  border-radius: 0.8rem;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.24);
  margin: 2.4rem 0 0 0;
  padding: 3.2rem 3.2rem 2.4rem 3.2rem;
  position: relative;
`;

export const PromoCardTag = styled(PromoTag)`
  position: absolute;
  top: -20px;
  left: 0;
`;

export const Title = styled.h4`
  color: var(--lightest);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 3.2rem;
  margin: 0rem 0 1.2rem 0;
`;

export const Ingridients = styled.p`
  color: var(--lightest);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 160%;
`;

export const PromoCardPrices = styled(Prices)`
  align-items: center;
  display: flex;
  margin: 1.8rem 0 0 0;

  > .price {
    .currency,
    .desc,
    > .--old > .value {
      color: rgba(255, 255, 255, 0.72) !important;
    }

    :not(:last-child) {
      margin-right: 1.6rem;
    }
  }
`;
