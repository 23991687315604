import styled from 'styled-components';

import Prices from '../../components/Prices';
import Spinner from '../../components/Spinner';

export const FlavourPageWrapper = styled.section`
  --borderRadius: 3.2rem;
  background-color: var(--primaryColor);
  margin: -3.2rem 0 0 0;
`;

export const FlavourContentWrapper = styled.article`
  background-color: var(--lightest);
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  padding: 3.2rem 3.2rem 5.6rem 3.2rem;

  @media (min-height: 700px) {
    box-shadow: 0 2px 0 var(--lightest);
  }

  @media (min-width: 768px) {
    padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  }

  @media (min-width: 1200px) {
    --pd: calc((100vw - 1200px) / 2);
    padding: 4.8rem var(--pd) 6.4rem var(--pd);
  }
`;

export const FlavourContactsWrapper = styled.div`
  background-color: var(--darkest);
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  margin: -3.2rem 0 0 0;
  padding: 3.2rem;

  @media (min-height: 700px) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media (min-width: 768px) {
    padding: 6.4rem;
  }
  @media (min-width: 1200px) {
    --pd: calc((100vw - 1200px) / 2);
    padding: 4.8rem var(--pd) 6.4rem var(--pd);
  }
`;

export const FlavourHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.8rem 0;
`;

export const FlavourTitle = styled.h1`
  color: var(--darkest);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 3.2rem;
  line-height: 128%;
`;

export const FlavourIngridients = styled.p`
  color: var(--dark);
  font-size: 1.6rem;
  line-height: 136%;
`;

export const FlavourTagsWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 1.6rem 0 0 0;

  & > *:not(:last-child) {
    margin: 0 0.8rem 0 0;
  }
`;

export const FlavourPricesWrapper = styled(Prices)`
  align-items: flex-end;
  column-gap: 0.8rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  row-gap: 1.6rem;

  .--old > .value,
  .--old > .currency,
  .desc {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .--promo > .currency {
    color: var(--auxColor) !important;
  }

  > .price:not(.--old):not(.--promo) {
    .currency,
    > .value {
      color: var(--primaryColor);
    }
  }
`;

export const FlavourPhoto = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20rem;
  margin: 3.2rem 0 0 0;
  width: 100%;

  @media (min-height: 768px) {
    height: 24rem;
  }

  @media (min-height: 1024px) {
    height: 32rem;
  }
`;

export const FlavourContactsContainer = styled.div`
  align-items: center;
  display: grid;
  column-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: 1.6rem 0 0 0;
`;

export const baseButton = styled.a`
  align-items: center;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  line-height: 120%;
  min-height: 4.8rem;
  outline-color: var(--lightest);
  padding: 0.4rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 400ms;
  transform-origin: center;
  will-change: transform;
  width: 100%;

  > .icon {
    height: 1.8rem;
    width: 1.6rem;
    margin: 0 0 0 0.8rem;
    @media (min-width: 321px) {
      margin: 0 0.64rem 0 0;
    }
  }

  :hover {
    transform: scale(1.12);
  }

  :active {
    transform: scale(0.88);
  }
`;

export const PhoneButton = styled(baseButton)`
  background-color: var(--primaryColor);
  color: var(--darkest);

  > .icon {
    fill: var(--darkest);
  }
`;

export const WhatsAppButton = styled(baseButton)`
  background-color: var(--auxColor);
  color: var(--lightest);

  > .icon {
    fill: var(--lightest);
  }
`;

const FlavourSpinner = styled(Spinner)`
  padding: 1.6rem 3.2rem;
  > div {
    border-width: 0.64rem;
    height: 4.8rem;
    width: 4.8rem;
  }
`;

export const FlavourContactsSpinner = styled(FlavourSpinner)`
  > div {
    border-bottom-color: var(--primaryColor);
  }
`;

export const FlavourContentSpinner = styled(FlavourSpinner)`
  padding: calc((100vh / 2) - 168px) 3.2rem;
  > div {
    border-bottom-color: var(--darkest);
  }
`;
