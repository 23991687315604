import React from 'react';
import PropTypes from 'prop-types';

import { $whatsApp } from '../../services';

import { Share } from '../../styles/icons';

import * as S from './styled';

function ShareButton({ target }) {
  const message = `Olha esse ${target} da Casa da Pizza!%0AEstá incrível!%0A${window.location.href}`;
  const href = `${$whatsApp.getLink()}text=${message}`;
  return (
    <S.ShareButton
      title="Compartilhar no WhatsApp"
      alt="Compartilhar no WhatsApp"
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      <S.ShareIcon>
        <Share />
      </S.ShareIcon>
    </S.ShareButton>
  );
}

ShareButton.defaultProps = { target: 'cardápio' };
ShareButton.propTypes = { target: PropTypes.string };

export default ShareButton;
