import styled from 'styled-components';

export const MessageWrapper = styled.div`
  align-items: center;
  background-color: var(--light);
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 32rem;
  margin: -100% --100%;
  opacity: 0;
  padding: 1.6rem 3.2rem;
  transition: margin 100ms, opacity 400ms, visibility 10ms;
  visibility: hidden;
  width: 100%;
  will-change: margin, opacity, visibility;

  &.--active {
    margin: 1.6rem auto 0 auto;
    opacity: 1;
    visibility: visible;
  }
`;

export const MessageContent = styled.p`
  color: var(--dark);
  font-size: 1.6rem;
  line-height: 148%;
`;
