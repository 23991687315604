import React from 'react';
import PropTypes from 'prop-types';

import Value from './Value';

import { propTypes as Type } from './type';

function Price({ desc, full, value, promo }) {
  const promoValue = promo && promo.value ? promo.value : null;

  return (
    <div className="price">
      {promoValue ? (
        // With promo value
        <>
          <Value value={value} desc={desc} mode="old" />
          <Value value={promoValue} desc={desc} mode="promo" full={full} />
        </>
      ) : (
        // Without promo
        <Value value={value} desc={desc} full={full} />
      )}
    </div>
  );
}

Price.defaultProps = { full: false, promo: { title: '', value: 0 } };

Price.propTypes = { ...Type, full: PropTypes.bool };

export default Price;
