import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Group({ title, style, className, children }) {
  return (
    <S.GroupWrapper style={style} className={className}>
      {title ? <S.GroupTitle>{title}</S.GroupTitle> : null}
      <S.GroupItemsWrapper>{children}</S.GroupItemsWrapper>
    </S.GroupWrapper>
  );
}

Group.defaultProps = {
  className: '',
  style: {},
  title: '',
};

Group.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
};

export default Group;
