import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function SearchToggler({ icon }) {
  return (
    <S.SearchToggler className="search-toggler" to="/pesquisa">
      <S.NavButtonIconWrapper>{icon}</S.NavButtonIconWrapper>
    </S.SearchToggler>
  );
}

SearchToggler.propTypes = { icon: PropTypes.node.isRequired };

export default SearchToggler;
