import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as S from './styled';

function GoBack({ message, icon, history }) {
  function onClickHandler() {
    if (history.action === 'POP') return history.push('/');
    return history.goBack();
  }

  return (
    <S.GoBack className="button goback" onClick={onClickHandler}>
      <S.NavButtonIconWrapper>{icon}</S.NavButtonIconWrapper>
      {message}
    </S.GoBack>
  );
}

GoBack.defaultProps = { message: 'Voltar' };

GoBack.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  message: PropTypes.string,
};

export default withRouter(GoBack);
