import React from 'react';

import { Menu } from '../pages';

export default [
  {
    index: 1,
    sidebar: true,
    label: 'Cardápio',
    name: 'Menu',
    path: '/menu',
    component: () => <Menu />,
  },
];
