import React from 'react';
import PropTypes from 'prop-types';

import Icons from './icons';

import * as S from './styled';

function MenuEntryTag({ className, label, small, target }) {
  const capitalize = value =>
    value
      .split(' ')
      .map(each => {
        if (new RegExp(/^(de|da|do)$/).test(each)) return each;
        return `${each.slice(0, 1).toUpperCase()}${each
          .slice(1)
          .toLowerCase()}`;
      })
      .join(' ');

  const flavourLabel = capitalize(label || target);

  const iconsRef = {
    brócolis: Icons.brocolis,
    'esfiha doce': Icons.esfihasDoces,
  };
  const icon = Object.keys(iconsRef).includes(target)
    ? iconsRef[target]
    : Icons[target];

  return (
    <S.MenuEntryContainer className={className}>
      <S.MenuEntryWrapper
        className={`menu-entry-tag ${small ? '--small' : ''} ${
          label ? '--withLabel' : ''
        }`}
      >
        <S.MenuEntryIcon
          className="tag-icon"
          src={icon}
          title={flavourLabel}
          alt={flavourLabel}
        />
      </S.MenuEntryWrapper>
      {label ? <S.TagLabel>{flavourLabel}</S.TagLabel> : null}
    </S.MenuEntryContainer>
  );
}

MenuEntryTag.defaultProps = {
  className: '',
  label: '',
  small: true,
};

MenuEntryTag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  small: PropTypes.bool,
  target: PropTypes.string.isRequired,
};

export default MenuEntryTag;
