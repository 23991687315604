import React from 'react';
import PropTypes from 'prop-types';

import Prices from '../Prices';
import PricesType from '../Prices/type';

import * as S from './styled';

function MenuEntry({ title, pathname, tags, prices, thumb }) {
  const Thumb = thumb ? (
    <S.MenuEntryThumbWrapper>
      <S.MenuEntryThumb externalPath={thumb} title={title} />
    </S.MenuEntryThumbWrapper>
  ) : null;

  return (
    <S.LinkWrapper to={`/menu/${pathname}`}>
      <S.Card>
        <S.Title>{title}</S.Title>

        {Thumb}
        <S.MenuEntryPromoTag prices={prices} />

        <S.MenuEntryFlavourTagsWrapper>
          {tags.map(each => (
            <S.MenuEntryFlavourTag target={each} small key={each} />
          ))}
        </S.MenuEntryFlavourTagsWrapper>

        <Prices prices={prices} />
      </S.Card>
    </S.LinkWrapper>
  );
}

MenuEntry.defaultProps = { thumb: '' };

MenuEntry.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  prices: PricesType.isRequired,
  thumb: PropTypes.string,
};

export default MenuEntry;
