import $http from './http.service';

const basePath = '/menu-entries';
const baseParams = { field: 'active', value: true };

const fetchAll = () =>
  $http
    .get(basePath, { params: { ...baseParams } })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

const fetchHome = () =>
  $http
    .get(`${basePath}/home`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

const fetchOne = pathname =>
  $http
    .get(`${basePath}/${pathname}`, { params: { ...baseParams } })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

export default { fetchAll, fetchHome, fetchOne };
