import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { $contacts } from '../../services';

import Brand from '../Brand';
import Close from './Close';
import Entry from './Entry';
import Social from './Social';

import * as S from './styled';

function Sidebar({ active, entries, onClose }) {
  const [social, setSocial] = useState([]);
  useEffect(() => {
    $contacts.fetchSocial().then(payload => {
      setSocial(payload.social);
    });
  }, []);

  return (
    <S.Wrapper className={active ? '--active' : ''}>
      <S.Overlay className="overlay" onClick={onClose}></S.Overlay>
      <S.Sidebar className="sidebar">
        <S.Header>
          <Brand alt="Casa da Pizza" />
          <Close onClick={onClose} />
        </S.Header>
        <S.NavLinks className="sidebar-nav">
          {entries.map(entry => (
            <Entry {...entry} key={entry.slug} />
          ))}
        </S.NavLinks>
        {social.length > 0 ? (
          <S.SocialLinks className="socials">
            {social.map(each => (
              <Social {...each} key={each.label} />
            ))}
          </S.SocialLinks>
        ) : null}
      </S.Sidebar>
    </S.Wrapper>
  );
}

Sidebar.defaultProps = {
  entries: [],
};

Sidebar.propTypes = {
  active: PropTypes.bool.isRequired,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func.isRequired,
};

export default Sidebar;
