import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Header({ className, children, imageBg }) {
  return (
    <S.Header
      className={`header ${className} ${imageBg ? '--image-background' : ''}`}
    >
      {children}
    </S.Header>
  );
}

Header.defaultProps = { className: '', children: null, imageBg: false };

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  imageBg: PropTypes.bool,
};

export default Header;
