import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Spinner({ active, className }) {
  return (
    <S.SpinnerWrapper
      className={`wrapper ${active ? '--active' : ''} ${className}`}
    >
      <S.Spinner />
    </S.SpinnerWrapper>
  );
}

Spinner.defaultProps = { active: true, className: '' };
Spinner.propTypes = { active: PropTypes.bool, className: PropTypes.string };

export default Spinner;
