import React from 'react';
import PropTypes from 'prop-types';

import GoBack from './GoBack';
import SidebarToggler from './SidebarToggler';
import SearchToggler from './SearchToggler';

import Icons from './icons';

import * as S from './styled';

function Navbar({ root, onSidebarToggle }) {
  return (
    <S.Nav className="nav">
      {root ? (
        <SidebarToggler icon={<Icons.menu />} onClick={onSidebarToggle} />
      ) : (
        <GoBack icon={<Icons.chevron />} />
      )}
      <S.NavBrand alt="Casa da Pizza" />
      <SearchToggler icon={<Icons.search />} />
    </S.Nav>
  );
}

Navbar.defaultProps = { root: true };

Navbar.propTypes = {
  root: PropTypes.bool,
  onSidebarToggle: PropTypes.func.isRequired,
};

export default Navbar;
