import React from 'react';
import PropTypes from 'prop-types';

import BrandImg from '../../assets/images/brand.png';

function Brand({ className, alt }) {
  return <img alt={alt} title={alt} className={className} src={BrandImg} />;
}

Brand.defaultProps = {
  className: '',
};

Brand.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

export default Brand;
