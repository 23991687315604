import React, { useEffect, useState, createRef } from 'react';

import { $menuEntries } from '../../services';

import Search from '../../components/Search';
import SearchResult from '../../components/SearchResult';

import * as S from './styled';

function SearchPage() {
  const resultsGroupRef = createRef();
  const [state, setState] = useState({
    entries: [],
    payload: [],
    filter: '',
    loading: false,
  });

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }));

    $menuEntries
      .fetchAll()
      .then(data => {
        const payload = data;
        const entries = data;
        let filter = '';

        const url = window.location.href;
        const flavour = url.split('?tag=')[1] || null;
        if (flavour) filter = flavour;

        setState(prev => ({ ...prev, entries, payload, filter }));
      })
      .finally(() => {
        setState(prev => ({ ...prev, loading: false }));
      });
  }, []);

  useEffect(() => {
    const { payload, filter } = state;
    let entries = payload;
    if (filter) entries = payload.filter(each => each.tags.includes(filter));

    setState(prev => ({ ...prev, entries }));
    if (resultsGroupRef.current) {
      resultsGroupRef.current.children[0].children[0].scroll({
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [state.filter]);

  function onSelectFilterHandler(tag) {
    let { filter } = state;
    if (tag === filter) filter = '';
    else filter = tag;

    return setState(prev => ({ ...prev, filter }));
  }

  return (
    <S.SearchLayout title="Pesquisa">
      <S.SearchResultsWrapper>
        <S.SearchParamsGroup title="Pesquisar por:">
          <Search active={state.filter} onSelect={onSelectFilterHandler} />
        </S.SearchParamsGroup>

        {state.loading ? <S.SearchSpinner active /> : null}
        {!state.loading && state.payload.length > 0 ? (
          <>
            <S.SearchResultsCount>{`${state.entries.length} itens disponíveis`}</S.SearchResultsCount>
            <div ref={resultsGroupRef}>
              <S.SearchResultsGroup>
                {state.entries.map(entry => (
                  <SearchResult {...entry} key={entry.pathname} />
                ))}
              </S.SearchResultsGroup>
            </div>
          </>
        ) : null}
      </S.SearchResultsWrapper>
    </S.SearchLayout>
  );
}

export default SearchPage;
