import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PromoTag from '../PromoTag';

export const SearchCardContainer = styled(Link)`
  text-decoration: none;
`;

export const SearchCard = styled.section`
  background-color: var(--light);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  position: relative;
  padding: 3.2rem;

  &.--hasPromo {
    margin: 1.4rem 0 0 0;
  }
`;

export const SearchTitle = styled.h1`
  color: var(--darkest);
  font-family: 'Caveat', 'Roboto', sans-serif;
  font-size: 2.4rem;
  line-height: 128%;
  margin: 0 0 0.4rem 0;
`;

export const SearchIngridients = styled.p`
  color: var(--dark);
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 144%;
  margin: 0 0 0.8rem 0;
`;

export const SearchTag = styled(PromoTag)`
  position: absolute;
  right: 0;
  top: -12px;
`;
