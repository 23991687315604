import React, { useState, useEffect } from 'react';

import { $menuEntries } from '../../services';

import Layout from '../../components/Layout';

import PromoCard from '../../components/PromoCard';
import MenuEntry from '../../components/MenuEntry';
import Message from '../../components/Message';
import Search from '../../components/Search';
import ShareButton from '../../components/ShareButton';

import * as S from './styled';

const MenuPage = () => {
  const [state, setState] = useState({
    payload: [],
    entries: [],
    filter: '',
    loading: false,
  });

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }));

    $menuEntries
      .fetchAll()
      .then(response => {
        setState(prev => ({
          ...prev,
          payload: response,
          entries: response,
        }));
      })
      .finally(() => {
        setState(prev => ({ ...prev, loading: false }));
      });
  }, []);

  useEffect(() => {
    let { payload: entries } = state;
    if (state.filter) {
      entries = state.payload.filter(each => each.tags.includes(state.filter));
    }

    setState(prev => ({ ...prev, entries }));
  }, [state.filter]);

  function onSearchSelectHandler(tag) {
    let filter = tag;
    if (state.filter === tag) filter = '';
    return setState(prev => ({ ...prev, filter }));
  }

  function Header() {
    const { loading, payload } = state;
    return (
      <>
        {loading ? <S.HeaderSpinner active /> : null}
        {!loading && payload.length > 0 ? (
          <S.HeaderGroup>
            {payload
              .filter(each => each.prices.find(price => price.promo))
              .map(promo => (
                <PromoCard {...promo} key={promo.pathname} />
              ))}
          </S.HeaderGroup>
        ) : null}
        {!loading && payload.legth <= 1 ? 'Not Found' : null}
      </>
    );
  }

  return (
    <Layout header={<Header />} title="Cardápio">
      <S.PageContainer>
        <S.SearchGroup title="O que você procura hoje?">
          <Search active={state.filter} onSelect={onSearchSelectHandler} />
        </S.SearchGroup>

        {state.loading ? <S.MenuSpinner active /> : null}
        {!state.loading && state.entries.length > 0 ? (
          <>
            <S.ResultsCounter>
              {`${state.entries.length} itens disponíveis`}
            </S.ResultsCounter>
            <S.MenuList>
              {state.entries.map(entry => (
                <MenuEntry {...entry} key={entry.pathname} />
              ))}
            </S.MenuList>
          </>
        ) : null}
        {!state.loading && state.entries.length <= 0 ? (
          <Message content="Não existem itens registrados" active />
        ) : null}
      </S.PageContainer>
      <ShareButton />
    </Layout>
  );
};

export default MenuPage;
