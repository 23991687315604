import $http from './http.service';

const basePath = '/contacts';

const fetchAll = () =>
  $http
    .get(basePath)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

const fetchSocial = () =>
  $http
    .get(`${basePath}/social`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

const fetchPhones = () =>
  $http
    .get(`${basePath}/phones`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });

export default { fetchAll, fetchSocial, fetchPhones };
