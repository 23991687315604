import React from 'react';

import { About, Home, NotFound } from '../pages';

export default [
  {
    index: 2,
    sidebar: true,
    label: 'Sobre',
    name: 'About',
    path: '/sobre',
    component: () => <About />,
  },
  {
    sidebar: true,
    index: 0,
    label: 'Home',
    name: 'Home',
    path: '/',
    exact: true,
    component: props => <Home {...props} />,
  },
  {
    sidebar: false,
    name: '404',
    path: '*',
    component: () => <NotFound />,
  },
];
