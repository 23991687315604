import React from 'react';
import DragScrollProvider from 'drag-scroll-provider';
import PropTypes from 'prop-types';

import options from './content';

import * as S from './styled';

function Search({ active, className, onSelect }) {
  const filters = {
    esfihasDoces: 'esfiha doce',
    brocolis: 'brócolis',
  };

  function onOptionClickHandler(option) {
    return onSelect(
      Object.keys(filters).includes(option) ? filters[option] : option,
    );
  }

  return (
    <div className={className}>
      <DragScrollProvider>
        {({ onMouseDown, ref, clickItem }) => (
          <S.Wrapper ref={ref} onMouseDown={onMouseDown} className="search">
            {options.map(option => (
              <S.SearchOption
                {...option}
                active={
                  active === option.value || active === filters[option.value]
                }
                key={option.value}
                onClick={() => {
                  clickItem(() => onOptionClickHandler(option.value));
                }}
              />
            ))}
          </S.Wrapper>
        )}
      </DragScrollProvider>
    </div>
  );
}

Search.defaultProps = { className: '' };

Search.propTypes = {
  active: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default Search;
