export default `
  :root {
    --primaryColor: #F1D302;
    --secondaryColor: #e0271b;
    --auxColor: #20bf55;

    --dark: #212121;
    --darkest: #000;

    --light: #ededed;
    --lightest: #fff;
  }
`;
