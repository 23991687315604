import React from 'react';
import PropTypes from 'prop-types';

import PricesType from '../Prices/type';

import * as S from './styled';

function PromoCard({ title, ingridients, pathname, prices }) {
  return (
    <S.Container to={`/menu/${pathname}`}>
      <S.Wrapper className="promo">
        <S.Title className="title">{title}</S.Title>
        <S.Ingridients className="ingridients">{ingridients}</S.Ingridients>

        <S.PromoCardTag prices={prices} />
        <S.PromoCardPrices prices={prices} />
      </S.Wrapper>
    </S.Container>
  );
}

PromoCard.propTypes = {
  title: PropTypes.string.isRequired,
  ingridients: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  prices: PricesType.isRequired,
};

export default PromoCard;
