import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { $menuEntries } from '../../services';

import Layout from '../../components/Layout';

import FlavourCard from '../../components/FlavourCard';
import PromoCard from '../../components/PromoCard';
import Message from '../../components/Message';

import * as S from './styled';

const HomePage = ({ history: { push } }) => {
  const [promos, setPromos] = useState([]);
  const [mostSold, setMostSold] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    $menuEntries
      .fetchHome()
      .then(payload => {
        const promosPayload = payload.filter(each =>
          each.prices.find(price => price.promo),
        );
        const mostSoldPayload = payload.filter(each => each.mostSold);

        setPromos(promosPayload);
        setMostSold(mostSoldPayload);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function onSearchSelectHandler(tag) {
    return push(`/pesquisa?tag=${tag}`);
  }

  return (
    <Layout header={<S.HomeHeader />} title="Home" headerBg>
      <S.PromosGroup title="Promoções">
        {loading ? <S.GroupSpinner /> : null}
        {!loading && promos.length > 0
          ? promos.map(promo => <PromoCard {...promo} key={promo.pathname} />)
          : null}
        {!loading && promos.length <= 0 ? (
          <Message content="Sem itens registrados!" active />
        ) : null}
      </S.PromosGroup>
      <S.FlavoursGroup title="As mais vendidas" style={{ marginTop: '3.2rem' }}>
        {loading ? <S.GroupSpinner /> : null}
        {!loading && mostSold.length > 0
          ? mostSold.map(flavour => (
              <FlavourCard {...flavour} key={flavour.pathname} />
            ))
          : null}
        {!loading && mostSold.length < 0 ? (
          <Message content="Sem itens registrados!" active />
        ) : null}
      </S.FlavoursGroup>
      <S.SearchGroup title="O que procura hoje?">
        <S.HomeSearch active="" onSelect={onSearchSelectHandler} />
      </S.SearchGroup>
    </Layout>
  );
};

HomePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default HomePage;
