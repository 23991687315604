import React from 'react';

import { Flavour } from '../pages';

export default [
  {
    sidebar: false,
    name: 'flavour',
    path: '/menu/:pathname',
    component: props => <Flavour {...props} />,
  },
];
