import styled from 'styled-components';

import Group from '../../components/Group';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';

export const SearchLayout = styled(Layout)`
  background-color: var(--primaryColor);
  min-height: 100vh;

  .header {
    box-shadow: none;
  }
`;

export const SearchResultsWrapper = styled.div`
  background-color: var(--lightest);
  border-radius: 2.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  margin: -4rem 0 0 0;
  padding: 3.2rem;
`;

export const SearchResultsCount = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.4rem;
  margin: 1.6rem 0 0 0;
  text-align: right;

  @media (min-width: 1200px) {
    margin: 2.4rem auto 0 auto;
    max-width: 1200px;
  }
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const SearchSpinner = styled(Spinner)`
  padding: 6.4rem 3.2rem;

  > div {
    height: 4rem;
    border-width: 0.64rem;
    border-bottom-color: var(--darkest);
    width: 4rem;
  }
`;

export const SearchResultsGroup = styled(Group)`
  padding: 1.6rem 0 0 0;

  > div {
    @media (min-width: 1024px) {
      display: grid;
      row-gap: 1.6rem;
      column-gap: 1.6rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
    }

    @media (min-width: 1200px) {
      margin: 0 auto;
      max-width: 1200px;
    }
    @media (min-width: 1400px) {
      max-width: 1400px;
    }

    > * {
      width: 28rem;
      @media (min-width: 1024px) {
        width: 100%;
      }

      :not(:last-of-type) {
        margin-right: 1.6rem;
        @media (min-width: 1024px) {
          margin-right: 0;
        }
      }
    }
  }
`;

export const SearchParamsGroup = styled(Group)`
  padding: 0;

  @media (min-width: 1024px) {
    > div {
      justify-content: center;
    }
    > h1 {
      text-align: center;
    }
  }
`;
