import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  width: fit-content;

  &.--old {
    --color: rgba(0, 0, 0, 0.24);
    --lnColor: #a29c9e;

    position: relative;
    margin-bottom: 0.32rem;

    > .value {
      font-size: 14px;
      font-weight: 400;
    }

    > .currency,
    > .desc {
      font-size: 10px;
      padding-bottom: 1px;
    }

    > .currency,
    > .value,
    > .desc {
      color: var(--color);
    }

    &::after {
      background-color: var(--lnColor);
      content: '';
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
    }
  }

  &.--promo {
    > .value {
      color: var(--auxColor);
    }
  }

  &.--full {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'currency value'
      'desc desc';

    > .desc {
      grid-area: desc;
    }
  }
`;

export const Small = styled.small`
  font-size: 12px;
  padding-bottom: 2px;
  text-transform: uppercase;
`;

export const Value = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0 4px;
`;
